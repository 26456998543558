import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Virtual from './Components/Blog/Virtual.js';
import WebBlog from './Components/Blog/WebBlog.js';
import Contact from './Components/Contact/Contact.js';

const Header = React.lazy(() => import("./Components/Global/Header"));
const Footer = React.lazy(() => import("./Components/Global/Footer"));
const PrivacyStatement = React.lazy(() => import("./Components/Global/PrivacyStatement"));
const CookiePolicy = React.lazy(() => import("./Components/Global/CookiePolicy"));
const RefundPolicy = React.lazy(() => import("./Components/Global/RefundPolicy"));
const ScrollToTop = React.lazy(() => import("./Components/ScrollToTop"));
const GoToTop = React.lazy(() => import("./Components/GoToTop"));
const Home = React.lazy(() => import("./Components/Home/Home"));
const Image1 = React.lazy(() => import("./Components/Home/Image1.js"));
const NewSection = React.lazy(() => import("./Components/Home/NewSection.js"));
const About = React.lazy(() => import("./Components/About/About"));
const Blog = React.lazy(() => import("./Components/Blog/Blog"));
const Blog2 = React.lazy(() => import("./Components/Blog/Blog2"));
const Awsblog = React.lazy(() => import("./Components/Blog/Awsblog"));
const Servicenow = React.lazy(() => import("./Components/Blog/Servicenow.js"));
const Mobileapp = React.lazy(() => import("./Components/Blog/Mobileapp"));
const Salesforceapi = React.lazy(() => import("./Components/Blog/Salesforceapi"));
const Socialmedia = React.lazy(() => import("./Components/Blog/Socialmedia"));
const Pro6tips = React.lazy(() => import("./Components/Blog/Pro6tips"));
const InspiringDigital = React.lazy(() => import("./Components/Blog/InspiringDigital"));
const RemoteWorking = React.lazy(() => import("./Components/Blog/RemoteWorking"));
const ProductVisualisation = React.lazy(() => import("./Components/Blog/ProductVisualisation"));
const DedicatedRemoteDeveloper = React.lazy(() => import("./Components/Blog/DedicatedRemoteDeveloper"));
const Top10software = React.lazy(() => import("./Components/Blog/Top10software"));
const UnlockingtheFuture = React.lazy(() => import("./Components/Blog/UnlockingtheFuture "));
const Service = React.lazy(() => import("./Components/Service/Service"));
const Insights = React.lazy(() => import("./Components/Insights/Insights"));
const Contactnew = React.lazy(() => import("./Components/Contact/Contactnew"));
const Digitalmarketing = React.lazy(() => import("./Components/Service/Digitalmarketing"));
const Industry = React.lazy(() => import("./Components/Industry/Industry"));
const Mernstackdevelopment = React.lazy(() => import("./Components/Service/Mernstackdevelopment"));
const Uiux = React.lazy(() => import("./Components/Service/Uiux"));
const Webdevelopment = React.lazy(() => import("./Components/Service/webdevelopment"));
const Mobiledevelopment = React.lazy(() => import("./Components/Service/Mobiledevelopment"));
const Ecomdevelopment = React.lazy(() => import("./Components/Service/Ecomdevelopment"));
const Datascience = React.lazy(() => import("./Components/Emerging/Datascience"));
const Applicationservices = React.lazy(() => import("./Components/Applicationservices"));
const Staffing = React.lazy(() => import("./Components/Staffing"));
const Itconsulting = React.lazy(() => import("./Components/Itconsulting"));
const Emerging = React.lazy(() => import("./Components/Emerging"));
const Webdesignanddevelopment = React.lazy(() => import("./Components/Service/Webdesignanddevelopment"));
const Enterprisesolutions = React.lazy(() => import("./Components/Service/Enterprisesolutions"));
const Qatesting = React.lazy(() => import("./Components/Service/Qatesting"));
const Graphicsdesign = React.lazy(() => import("./Components/Service/Graphicsdesign"));
const Wordpressdevelopment = React.lazy(() => import("./Components/Service/Wordpressdevelopment"));
const Phpdevelopment = React.lazy(() => import("./Components/Service/Phpdevelopment"));
const ECommerce = React.lazy(() => import("./Components/Service/ECommerce"));
const RecruitmentService = React.lazy(() => import("./Components/Service/RecruitmentService"));
const StaffAugementation = React.lazy(() => import("./Components/Service/StaffAugementation"));
const Staffingservices = React.lazy(() => import("./Components/Service/Staffingservices"));
const Recrutimentservices = React.lazy(() => import("./Components/Service/Recrutimentservices"));
const HealthCare = React.lazy(() => import("./Components/Industry/Healthcare"));
const Hospitility = React.lazy(() => import("./Components/Industry/Hospitility"));
const Banking = React.lazy(() => import("./Components/Industry/Banking"));
const Education = React.lazy(() => import("./Components/Industry/Education"));
const HighTechnology = React.lazy(() => import("./Components/Industry/HighTechnology"));
const Retail = React.lazy(() => import("./Components/Industry/Retail"));
const Automotive = React.lazy(() => import("./Components/Industry/Automotive"));
const Utilities = React.lazy(() => import("./Components/Industry/Utilities"));
const Insurance = React.lazy(() => import("./Components/Industry/Insurance"));
const MediaEntertainment = React.lazy(() => import("./Components/Industry/MediaEntertainment"));
const TourTravel = React.lazy(() => import("./Components/Industry/TourTravel"));
const AerospaceDefence = React.lazy(() => import("./Components/Industry/AerospaceDefence"));
const Career = React.lazy(() => import("./Components/Career/Career"));
const SearchEngineOptimization = React.lazy(() => import("./Components/DigitalMarketing/SearchEngineOptimization"));
const PayPerClick = React.lazy(() => import("./Components/DigitalMarketing/PayPerClick"));
const BlockChain = React.lazy(() => import("./Components/Emerging/Blockchain"));
const Automation = React.lazy(() => import("./Components/Emerging/Automation"));
const Extendedreality = React.lazy(() => import("./Components/Emerging/Extendedreality"));
const Cognitivecomputing = React.lazy(() => import("./Components/Emerging/Cognitivecomputing"));
const Cloud = React.lazy(() => import("./Components/Emerging/Cloud"));
const AI = React.lazy(() => import("./Components/Emerging/AI"));
const SocialMediaMarketing = React.lazy(() => import("./Components/DigitalMarketing/SocialMediaMarketing"));
const ContentMarketing = React.lazy(() => import("./Components/DigitalMarketing/ContentMarketing"));
const EmailMarketing = React.lazy(() => import("./Components/DigitalMarketing/EmailMarketing"));
const CustomDevelopment = React.lazy(() => import("./Components/Service/CustomDevelopment"));
const CMS = React.lazy(() => import("./Components/Service/CMS"));
const API = React.lazy(() => import("./Components/Service/API"));
const Salesforce = React.lazy(() => import("./Components/Consulting/Salesforce.js"));
const DigitalTransformation = React.lazy(() => import("./Components/Consulting/DigitalTransformation.js"));
const DevOps = React.lazy(() => import("./Components/Consulting/DevOps.js"));
const Workday = React.lazy(() => import("./Components/Consulting/Workday.js"));
const Jira = React.lazy(() => import("./Components/Consulting/Jira.js"));
const Kronos = React.lazy(() => import("./Components/Consulting/Kronos.js"));
const Consulting = React.lazy(() => import("./Components/Consulting/Consulting.js"));
const ServiceNow = React.lazy(() => import("./Components/Consulting/ServiceNow.js"));
const ErrorPage = React.lazy(() => import("./Components/ErrorPage"));
const CaseStudy = React.lazy(() => import("./Components/CaseStudy"));
const Casestudyworkday = React.lazy(() => import("./Components/Casestudyworkday"));
const Casestudydevops = React.lazy(() => import("./Components/Casestudydevops"));
const Casestudysalesforce = React.lazy(() => import("./Components/Casestudysalesforce"));
const Casestudyservicenow = React.lazy(() => import("./Components/Casestudyservicenow"));
const Information = React.lazy(() => import("./Components/Information/Information"));
const Amitsir = React.lazy(() => import("./Components/Information/Amitsir"));
const Himanshu = React.lazy(() => import("./Components/Information/Himanshu"));
const Login = React.lazy(() => import("./Components/Login/Login"));
const LogApi = React.lazy(() => import("./Components/Login/LogApi"));
const ContactNewDashboard = React.lazy(() => import("./Components/Login/ContactNewDashboard"));
const CareerDashboard = React.lazy(() => import("./Components/Login/CareerDashboard"));
const HomeContactDashboard = React.lazy(() => import("./Components/Login/HomeContactDashboard"));
const SideBar = React.lazy(() => import("./Components/Login/SideBar"));
const Carr = React.lazy(() => import("./Components/Career/Carr"));
const NewContactDash = React.lazy(() => import("./Components/Login/NewContactDash"));
const NewHomeDash = React.lazy(() => import("./Components/Login/NewHomeDash"));
const NewCareerDash = React.lazy(() => import("./Components/Login/NewCareerDash"));
const Projectlogin = React.lazy(() => import("./Components/Login/Loginproject.jsx"));
const Dashboard = React.lazy(() => import("./Components/Login/Home.jsx"));
const Companydashboard = React.lazy(() => import("./Components/Information/Companydashboard.js"));
const ServiceStudynew = React.lazy(() => import("./Components/Servicenowstudy.js"));

function App() {
  
  return (
    
    <>
      <Router>
        <React.Suspense fallback={<div className='d-flex justify-content-center align-items-center'>
        </div>}>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/error-page" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route exact path="/about-us" element={<About />}/>
            <Route exact path="/blogs" element={<Blog />} />
            <Route exact path="/blog/connection-between-angular-and-aws" element={<Awsblog />} />
            <Route exact path="/blog/the-power-of-servicenow-in-business-service-management" element={<Servicenow />} />
            <Route exact path="/blog/crafting-a-winning-mobil-app-project" element={<Mobileapp />} />
            <Route exact path="/blog/business-operations-by-integration-of-salesforce" element={<Salesforceapi />} />
            <Route exact path="/blog/virtual-try-on-apps-development-revolutionizing-online-shopping-experience" element={<Virtual />} />
            <Route exact path="/blog/web-2.0-vs-web-3.0-a-digital-renaissance" element={<WebBlog />} />
            <Route exact path="/blog/crucial-social-media-marketing-principles" element={<Socialmedia />} />
            <Route exact path="/blog/pro-tips-for-selecting-the-top-mobile-app-development-company" element={<Pro6tips />} />
            <Route exact path="/blog/digital-transformation-example-to-guide-your-strategy" element={<InspiringDigital />} />
            <Route exact path="/blog/grow-your-business-with-dedicated-remote-developer-in-2023" element={<RemoteWorking />} />
            <Route exact path="/blog/how-can-extended-reality-be-used-for-product-visualisation" element={<ProductVisualisation />} />
            <Route exact path="/blog/secrets-of-remote-working-to-drive-productivity" element={<DedicatedRemoteDeveloper />} />
            <Route exact path="/blog/software-development-trends-in-2023" element={<Top10software />} />
            <Route exact path="/blog/benefits-of-application-development" element={<UnlockingtheFuture/>} />
            <Route exact path="/blogs2" element={<Blog2 />} />
            <Route exact path="/case-studies/servicenow-casestudy" element={<ServiceStudynew />} />
            <Route exact path="/services" element={<Service />} />
            <Route exact path="/insights" element={<Insights />} />
            <Route exact path="/contact" element={<Contactnew />} />
            <Route exact path="/contact-old" element={<Contact />} />
            <Route exact path="/digital-marketing-service" element={<Digitalmarketing />} />
            <Route exact path="/industries" element={<Industry />} />
            <Route exact path="/mern-stack-development" element={<Mernstackdevelopment />} />
            <Route exact path="/ui-ux-design-company" element={<Uiux />} />
            <Route exact path="/data-science" element={<Datascience />} />
            <Route exact path="/extendedreality" element={<Extendedreality />} />
            <Route exact path="/staffing-services" element={<Staffingservices />} />
            <Route exact path="/recruitment-services" element={<Recrutimentservices />} />
            <Route exact path="/web-design-and-development-company" element={<Webdevelopment />} />
            <Route exact path="/eCommerce-web-app-development-company" element={<Ecomdevelopment />} />
            <Route exact path="/mobile-app-development-company" element={<Mobiledevelopment />} />
            <Route exact path="/qatesting" element={<Qatesting />} />
            <Route exact path="/application-services" element={<Applicationservices />} />
            <Route exact path="/staffing" element={<Staffing />} />
            <Route exact path="/emerging" element={<Emerging />} />
            <Route exact path="/itconsulting" element={<Itconsulting />} />
            <Route exact path="/enterprisesolutions" element={<Enterprisesolutions />} />
            <Route exact path="/graphics-design-company" element={<Graphicsdesign />} />
            <Route exact path="/wordpress-development" element={<Wordpressdevelopment />} />
            <Route exact path="/php-development" element={<Phpdevelopment />} />
            <Route exact path="/recruitment-service" element={<RecruitmentService />} />
            <Route exact path="/staff-augementation-service-company" element={<StaffAugementation />} />
            <Route exact path="/industries/healthcare" element={<HealthCare />} />
            <Route exact path="/industries/Hospitility" element={<Hospitility />} />
            <Route exact path="/industries/banking" element={<Banking />} />
            <Route exact path="/industries/education" element={<Education />} />
            <Route exact path="/industries/high-technology" element={<HighTechnology />} />
            <Route exact path="/industries/retail" element={<Retail />} />
            <Route exact path="/industries/automotive" element={<Automotive />} />
            <Route exact path="/industries/utilities" element={<Utilities />} />
            <Route exact path="/industries/insurance" element={<Insurance />} />    
            <Route exact path="/industries/mediaentertainment" element={<MediaEntertainment />} />
            <Route exact path="/industries/tourtravel" element={<TourTravel />} />
            <Route exact path="/industries/aerospacedefence" element={<AerospaceDefence />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/search-engine-optimization-services-company" element={<SearchEngineOptimization />} />
            <Route exact path="/pay-per-click-service" element={<PayPerClick />} />
            <Route exact path="/blockchain-development-company" element={<BlockChain />} />
            <Route exact path="/ai-and-ml" element={<Cognitivecomputing />} />
            <Route exact path="/automation" element={<Automation />} />
            <Route exact path="/cloud-service-solution" element={<Cloud />} />
            <Route exact path="/artificial-intelligence-service" element={<AI />} />
            <Route exact path="/social-media-marketing-services" element={<SocialMediaMarketing />} />
            <Route exact path="/content-marketing-services" element={<ContentMarketing />} />
            <Route exact path="/email-marketing-services" element={<EmailMarketing />} />
            <Route exact path="/custom-software-development-services" element={<CustomDevelopment />} />
            <Route exact path="/content-management-development-services" element={<CMS />} />
            <Route exact path="/api-integration-services-company" element={<API />} />
            <Route exact path="/salesforce-services" element={<Salesforce />} />
            <Route exact path="/digital-transformation-consulting" element={<DigitalTransformation />} />
            <Route exact path="/devops-services" element={<DevOps />} />
            <Route exact path="/workday-services" element={<Workday />} />
            <Route exact path="/jira" element={<Jira />} />
            <Route exact path="/kronos-consulting-services" element={<Kronos />} />
            <Route exact path="/servicenow-consulting-services" element={<ServiceNow />} />
            <Route exact path="/consulting-services" element={<Consulting />} />
            <Route exact path="/case-studies" element={<CaseStudy />} />
            <Route exact path="/case-studies/case-study-workday" element={<Casestudyworkday />} />
            <Route exact path="/case-studies/case-study-devops" element={<Casestudydevops />} />
            <Route exact path="/case-studies/case-study-salesforce" element={<Casestudysalesforce />} />
            <Route exact path="/case-studies/case-study-servicenow" element={<Casestudyservicenow />} />
            <Route exact path="/harsh-keshare" element={<Information />} />
            <Route exact path="/amit-dubey" element={<Amitsir />} />
          
            <Route exact path="/Himanshu-pritmani" element={<Himanshu />} />
            <Route exact path="/privacy-policy" element={<PrivacyStatement/>} />
            <Route exact path="/cookie-policy" element={<CookiePolicy/>} />
            <Route exact path="/refund-policy" element={<RefundPolicy/>} />
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/login-dev" element={<LogApi/>} />
            <Route exact path="/contactNew-Dashboard" element={<ContactNewDashboard/>}/>
            <Route exact path="/career-Dashboard" element={<CareerDashboard/>}/>
            <Route exact path="/Home-Dashboard" element={<HomeContactDashboard/>}/>
            <Route exact path="/SideBar-Dashboard" element={<SideBar/>}/>
            <Route exact path="/carr-form" element={<Carr/>}/>
            <Route exact path="/NewContactDash" element={<NewContactDash/>}/>
            <Route exact path="/NewHomeDash" element={<NewHomeDash/>}/>
            <Route exact path="/NewCareerDash" element={<NewCareerDash/>}/>
            <Route exact path="/projectlogin" element={<Projectlogin/>}/>
            <Route exact path="/dashboard" element={<Dashboard/>}/>
            
            <Route exact path="/company-profile" element={<Companydashboard/>}/>
          </Routes>
          <GoToTop />
          <Footer />
        </React.Suspense>
      </Router>

    

    </>

    
  );
}

export default App;
